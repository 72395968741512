import { WebSdkModel } from 'packages/embedded-web-sdk';
import { IovationPlugin } from './types';
import { once } from './utils';

export interface WebSdkIovationPluginCreator {
  (exec: WebSdkModel['exec']): IovationPlugin;
}

export const createWebSdkIovationPlugin: WebSdkIovationPluginCreator = (
  exec
) => {
  const hasIovation = once<Promise<boolean>>(async () => {
    // Check to see if we have the iovation plugin available in the api
    const api = await exec({
      plugin: 'pluginManager',
      action: 'getApi',
      params: null
    });

    if (
      !api ||
      !api.iovation ||
      !api.iovation.hasIovation ||
      !api.iovation.getBlackBox
    ) {
      return false;
    }

    // Check to see if plugin itself thinks we have an iovation impl to use at runtime
    return await exec({
      plugin: 'iovation',
      action: 'hasIovation',
      params: null
    });
  });

  const getBlackBox = once<Promise<string | null>>(async () => {
    if (await hasIovation()) {
      return exec({
        plugin: 'iovation',
        action: 'getBlackBox',
        params: null
      });
    }
    return null;
  });

  return { hasIovation, getBlackBox };
};
