import { createTimeout } from './utils';

declare global {
  interface Window {
    io_flash_needs_update_handler?: string;
    io_install_stm?: boolean;
    io_exclude_stm?: number;
    io_enable_rip?: boolean;
    io_max_wait?: number;
    io_install_flash?: boolean;
    io_min_flash_version?: number;
    io_script_loaded?: () => void;
    io_bb_callback?: (fingerprint: string, isComplete: boolean) => void;
  }
}

const getIovationFingerprint = (
  windowObj: Window,
  timeout: number
): Promise<string> => {
  return new Promise((resolve) => {
    windowObj.io_flash_needs_update_handler = '';
    windowObj.io_install_stm = false;
    windowObj.io_exclude_stm = 12;
    windowObj.io_enable_rip = true;
    windowObj.io_max_wait = timeout;
    windowObj.io_install_flash = false;
    windowObj.io_script_loaded = () => {
      // noop
    };
    windowObj.io_bb_callback = (fingerprint: string, complete: boolean) => {
      if (complete) {
        resolve(fingerprint);
      }
    };
  });
};

const loadScript = async (windowObj: Window, iovationUrl: string) => {
  await new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = iovationUrl;
    script.async = true;
    script.onload = () => {
      if (windowObj.io_script_loaded) {
        windowObj.io_script_loaded();
      }
      resolve();
    };
    script.onerror = reject;
    document.body.appendChild(script);
  });
};

export const fetchScriptAndGenerateFingerprint = (
  windowObj: Window,
  iovationUrl: string,
  totalTimeout: number
): Promise<string> => {
  return Promise.race([
    loadScript(windowObj, iovationUrl).then(() => {
      return getIovationFingerprint(windowObj, totalTimeout);
    }),
    createTimeout(totalTimeout).then(() => {
      throw new Error(
        `Iovation script load & fingerprint generation timed out after ${totalTimeout}ms`
      );
    })
  ]);
};
