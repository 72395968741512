export const once = <T>(func: () => T): (() => T) => {
  let fn: (() => T) | null = func;
  let result: T;
  return () => {
    if (fn) {
      result = fn();
      fn = null;
    }
    return result;
  };
};

export const createTimeout = (timeout: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, timeout);
  });
};
