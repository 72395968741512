import { IovationPlugin } from './types';
import { createTimeout, once } from './utils';

const ioGlobalObjectName = 'IGLOO';

interface IovationFirstPartyWindow extends Window {
  [ioGlobalObjectName]: {
    bb_callback?: (bb: string, complete: boolean) => void;
    enable_flash?: boolean;
    loader: {
      subkey: string;
      version: string;
    };
  };
  io_global_object_name: string;
}

// Attempts to use proxy on current domain at /iojs/ to load external iovation site that serves the iovation library, will fallback to loading from iovation directly (this logic is in the loader)
export const createWebFirstPartyIovationPlugin = (
  windowObj: Window,
  timeout = 60000
): IovationPlugin => {
  const hasIovation = () => Promise.resolve(true);

  const getBlackBox = once(() =>
    loadSdkAndGenerateFingerprintWithTimeout(windowObj, timeout)
  );

  return { hasIovation, getBlackBox };
};

const loadSdkAndGenerateFingerprintWithTimeout = (
  windowObj: Window,
  totalTimeout: number
): Promise<string> => {
  let completed = false;
  return Promise.race([
    loadSdkAndGenerateFingerprint(windowObj as IovationFirstPartyWindow).then(
      (bb) => {
        completed = true;
        return bb;
      }
    ),
    createTimeout(totalTimeout).then(() => {
      if (completed) return ''; // don't throw error if loadSdkAndGenerateFingerprint() already won the race
      throw new Error(
        `Iovation SDK load & fingerprint generation timed out after ${totalTimeout}ms`
      );
    })
  ]);
};

const loadSdkAndGenerateFingerprint = async (
  windowObj: IovationFirstPartyWindow
) => {
  // Configure SDK
  const defaultConfig = {
    enable_flash: false,
    loader: {
      subkey: '',
      version: 'general5'
    }
  };
  windowObj.io_global_object_name = ioGlobalObjectName;
  const ioGlobalObject = (windowObj[ioGlobalObjectName] =
    windowObj[ioGlobalObjectName] || defaultConfig);

  const blackboxPromise = new Promise<string>((resolve) => {
    ioGlobalObject.bb_callback = (bb: string, ready: boolean) => {
      if (ready) resolve(bb);
    };
  });

  // Load SDK
  (await import('./first-party-loader')).default();

  return blackboxPromise;
};
