import { fetchScriptAndGenerateFingerprint } from './get-iovation-fingerprint-via-script-url';
import { IovationPlugin } from './types';
import { once } from './utils';

export const createWebIovationPlugin = (
  iovationUrl: string,
  timeout = 60000
): IovationPlugin => {
  const hasIovation = () => Promise.resolve(true);

  const getBlackBox = once(() =>
    fetchScriptAndGenerateFingerprint(window, iovationUrl, timeout)
  );

  return { hasIovation, getBlackBox };
};
