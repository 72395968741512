import { IovationPlugin } from './types';
import { once } from './utils';

// This type describes the object that the `cordova-plugin-package` in mobile-apps repo injects into `window` global
export interface IovationCordovaSdk {
  blackbox: (
    success: (data: string) => void,
    error: (e: string) => void
  ) => void;
}

export const createCordovaIovationPlugin = (
  iovation: IovationCordovaSdk
): IovationPlugin => {
  const hasIovation = () => Promise.resolve(true);

  const getBlackBox = once<Promise<string | null>>(async () => {
    if (await hasIovation()) {
      return new Promise((resolve, reject) => {
        iovation.blackbox(resolve, reject);
      });
    }
    return null;
  });

  return { hasIovation, getBlackBox };
};
